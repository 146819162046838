// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} A.O. Upright Technologies & Business Hub. All Rights Reserved.</p>
        <div className="social-media">
          <a href="https://web.facebook.com/profile.php?id=100090453010996" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://instagram.com/a.o_uprighttechnologies" target="_blank" rel="noopener noreferrer">Instagram</a>
          <a href="https://twitter.com/uprighttech21" target="_blank" rel="noopener noreferrer">Twitter</a>
        </div>
        <p>Contact: <a href="mailto:aouprighttechnologies@gmail.com">aouprighttechnologies@gmail.com</a></p>
      </div>
    </footer>
  );
};

export default Footer;
