import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // For redirecting the user
import './upload.css';

const UploadPage = () => {
  const [formData, setFormData] = useState({
    productName: '',
    description: '',
    image: null,
  });
  const [preview, setPreview] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  // Check for authentication on component mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login if no token
      navigate('/login');
    } else {
      // Set authenticated state if token exists
      setIsAuthenticated(true);
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Check if the uploaded file is an image
    if (!file || !file.type.startsWith('image/')) {
      setMessage('Please upload a valid image file (png, jpeg, etc.).');
      setFormData({ ...formData, image: null });
      setPreview(null);
      return;
    }

    setFormData({ ...formData, image: file });
    setPreview(URL.createObjectURL(file));
    setMessage(''); // Clear any previous messages
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.productName || !formData.description || !formData.image) {
      setMessage('All fields are required');
      return;
    }

    setLoading(true);

    const productData = new FormData();
    productData.append('name', formData.productName);
    productData.append('description', formData.description);
    productData.append('image', formData.image);

    const token = localStorage.getItem('token'); // Retrieve token for authorization

    try {
      const response = await axios.post('https://uprightback-yu5q.onrender.com/uploadproduct', productData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`, // Send token with the request
        },
      });

      if (response.status === 200) {
        setMessage('Product uploaded successfully!');
        setFormData({
          productName: '',
          description: '',
          image: null,
        });
        setPreview(null);
      } else {
        setMessage('Something went wrong!');
      }
    } catch (error) {
      setMessage(error.response?.data?.message || 'Error uploading the product.');
    } finally {
      setLoading(false);
    }
  };

  // Handle logout functionality
  const handleLogout = () => {
    localStorage.removeItem('token'); // Clear token from localStorage
    navigate('/login'); // Redirect to login page
  };

  if (!isAuthenticated) {
    return null; // Render nothing until the auth check completes
  }

  return (
    <div className="upload-page">
      <h1>Upload New Product</h1>
      {message && <div className={message.includes('success') ? 'success-message' : 'error-message'}>{message}</div>}
      <form onSubmit={handleSubmit}>
        <label htmlFor="productName">Product Name</label>
        <input
          type="text"
          id="productName"
          name="productName"
          value={formData.productName}
          onChange={handleInputChange}
        />

        <label htmlFor="description">Description</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
        />

        <label htmlFor="image">Product Image</label>
        <input type="file" id="image" name="image" onChange={handleFileChange} />
        {preview && <img src={preview} alt="Preview" className="preview-image" />}

        <button type="submit" className="btn-upload" disabled={loading}>
          {loading ? 'Uploading...' : 'Upload Product'}
        </button>
      </form>

      {/* Add Logout Button */}
      <button onClick={handleLogout} className="btn-logout">
        Logout
      </button>
    </div>
  );
};

export default UploadPage;
