import React from 'react';
import styled from 'styled-components';

const Contact = () => {
  const email = "aouprighttechnologies@gmail.com";
  const phoneNumber = "08067310853";
  const whatsappNumber = "+2348067310853";

  return (
    <ContactContainer>
      <h1>Contact Us</h1>
      <p>We’d love to hear from you. You can reach us through the following methods:</p>
      
      <ContactMethods>
        <ContactButton href={`mailto:${email}`} className="email-button">Email Us</ContactButton>
        <ContactButton href={`tel:${phoneNumber}`} className="phone-button">Call Us</ContactButton>
        <ContactButton 
          href={`https://wa.me/${whatsappNumber}`} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="whatsapp-button">
          Chat Us on WhatsApp
        </ContactButton>
      </ContactMethods>
      
      <p>If you prefer, you can also fill out the form below and we’ll get back to you soon!</p>
      <form>
        <Input type="text" name="name" placeholder="Your Name" required />
        <Input type="email" name="email" placeholder="Your Email" required />
        <TextArea name="message" placeholder="Your Message" required></TextArea>
        <Button type="submit">Send Message</Button>
      </form>
    </ContactContainer>
  );
};

export default Contact;

const ContactContainer = styled.div`
  padding: 4rem 2rem;
  background-color: #f5f5f5;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
  }

  p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2.5rem;
  }
`;

const ContactMethods = styled.div`
  margin: 1.5rem 0;
`;

const ContactButton = styled.a`
  display: inline-block;
  margin: 0.5rem 1rem;
  padding: 0.8rem 1.5rem;
  background-color: #ff6347;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #e5533f;
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const Input = styled.input`
  width: 100%;
  max-width: 500px;
  padding: 0.8rem;
  margin: 0.5rem 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    outline: none;
    border-color: #ff6347;
    box-shadow: 0 0 5px rgba(255, 99, 71, 0.5);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  max-width: 500px;
  padding: 1rem;
  margin: 0.5rem 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  min-height: 150px;
  transition: border 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    outline: none;
    border-color: #ff6347;
    box-shadow: 0 0 5px rgba(255, 99, 71, 0.5);
  }
`;

const Button = styled.button`
  padding: 0.8rem 2rem;
  margin-top: 1rem;
  background-color: #ff6347;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #e5533f;
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(1px);
  }

  &:focus {
    outline: none;
  }
`;
