import React from 'react';
import './Homepage.css'; // Ensure the CSS file is updated accordingly

const Homepage = () => {
  return (
    <div className="homepage">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1 className="hero-title">A.O. Upright Technologies & Business Hub</h1>
          <p className="hero-subtitle">Innovative Solutions for Food Processing and Industrial Equipment</p>
          <a href="#services" className="cta-button">Explore Our Services</a>
        </div>
      </section>

      {/* About the Company */}
      <section id="about" className="about">
        <h2>Who We Are</h2>
        <p>
          At A.O. Upright Technologies & Business Hub, we specialize in the fabrication, assembling, and installation of high-quality food processing and industrial equipment. We are committed to providing innovative, reliable, and customized solutions that meet the needs of our clients across Nigeria.
        </p>
      </section>

      {/* Services Section */}
      <section id="services" className="services">
        <h2>Our Expertise</h2>
        <div className="services-list">
          <div className="service-card">
            <div className="service-icon">🔧</div>
            <h3>Fabrication</h3>
            <p>Precision fabrication of food processing equipment with durability and high performance.</p>
          </div>
          <div className="service-card">
            <div className="service-icon">⚙️</div>
            <h3>Assembling & Installation</h3>
            <p>End-to-end assembling and installation of industrial machinery, tailored to your specifications.</p>
          </div>
          <div className="service-card">
            <div className="service-icon">📅</div>
            <h3>Project Scheduling</h3>
            <p>Efficient project management ensuring timely delivery and precision at every step.</p>
          </div>
          <div className="service-card">
            <div className="service-icon">💬</div>
            <h3>Live Client Communication</h3>
            <p>Real-time updates and active collaboration to keep you informed from design to delivery.</p>
          </div>
        </div>
      </section>

      {/* Testimonial Section */}
      <section id="testimonials" className="testimonials">
        <h2>What Our Clients Say</h2>
        <div className="testimonial-list">
          <div className="testimonial-card">
            <p>"A.O. Upright Technologies delivered exceptional quality and met every deadline. Their equipment has significantly improved our production efficiency!"</p>
            <h4>— Mepson Foods</h4>
          </div>
          <div className="testimonial-card">
            <p>"Highly professional and responsive team. We are incredibly satisfied with the installation and support services."</p>
            <h4>— Jo-tech Industry</h4>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section id="cta" className="cta-section">
        <h2>Ready to Elevate Your Production?</h2>
        <p>Contact us today for a consultation and discover how we can help your business thrive.</p>
        <a href="contact" className="cta-button">Get in Touch</a>
      </section>
    </div>
  );
};

export default Homepage;
