import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.css'; // For custom styling

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Hardcoded credentials
  const validEmail = 'Akinwandeabdurazaq@gmail.com'.toLowerCase();
  const validPassword = 'olaniyi1';

  const handleLogin = (e) => {
    e.preventDefault();
    
    // Simple hardcoded login check
    if (email.toLowerCase() === validEmail.toLowerCase() && password === validPassword) {
      localStorage.setItem('token', 'dummy-token'); // Simulate storing a token
      navigate('/upload'); // Redirect to upload page after successful login
    } else {
      setErrorMessage('Invalid email or password');
    }
  };

  return (
    <div className="login-page">
      <h1>Login</h1>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <form onSubmit={handleLogin}>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <button type="submit" className="btn-login">Login</button>
      </form>
    </div>
  );
};

export default LoginPage;
