// // // import React, { useState } from 'react';
// // // import './About.css';
// // // import Modal from './AboutModal'; 

// // // const About = () => {
// // //   const [isModalOpen, setIsModalOpen] = useState(false);
// // //   const [selectedImage, setSelectedImage] = useState('');

// // //   const openModal = (image) => {
// // //     setSelectedImage(image);
// // //     setIsModalOpen(true);
// // //   };

// // //   const closeModal = () => {
// // //     setIsModalOpen(false);
// // //     setSelectedImage('');
// // //   };

// // //   return (
// // //     <section id="about" className="about">
// // //       <h2>About Us</h2>
// // //       <p>
// // //         A.O. Upright Technologies & Business Hub, founded by Akinwande Abdulrazaq Olaniyi, specializes in the fabrication, assembling, and installation of food processing and industrial equipment. With a commitment to innovation and customer satisfaction, we deliver exceptional products tailored to your needs.
// // //       </p>
      
// // //       <h3>Our Mission</h3>
// // //       <p>
// // //         To provide high-quality technology solutions that enhance efficiency and productivity for our clients.
// // //       </p>
      
// // //       <h3>Our Vision</h3>
// // //       <p>
// // //         To be a leading provider of innovative industrial solutions across Africa, recognized for our quality and service excellence.
// // //       </p>
      
// // //       <h3>Our Values</h3>
// // //       <ul>
// // //         <li>Integrity</li>
// // //         <li>Innovation</li>
// // //         <li>Customer Focus</li>
// // //         <li>Excellence</li>
// // //       </ul>

// // //       {/* Certificates Section */}
// // //       <section className="certificates">
// // //         <h3>Our Certifications</h3>
// // //         <div className="certificate-gallery">
// // //           <img 
// // //             src="https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726936123/cac_hmnqos.jpg" 
// // //             alt="CAC Certificate" 
// // //             className="certificate-thumbnail"
// // //             onClick={() => openModal('https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726936123/cac_hmnqos.jpg')}
// // //           />
// // //           <img 
// // //             src="https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726936123/hicman_ljt2po.jpg" 
// // //             alt="Training Certificate" 
// // //             className="certificate-thumbnail"
// // //             onClick={() => openModal('https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726936123/hicman_ljt2po.jpg')}
// // //           />
// // //           <img 
// // //             src="https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726936123/amefan_npvxcv.jpg" 
// // //             alt="Amefan" 
// // //             className="certificate-thumbnail"
// // //             onClick={() => openModal('https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726936123/amefan_npvxcv.jpg')}
// // //           />
// // //         </div>
// // //       </section>

// // //       <Modal isOpen={isModalOpen} onClose={closeModal} image={selectedImage} />
// // //     </section>
// // //   );
// // // };

// // // export default About;
// // import React, { useState } from 'react';
// // import './AboutModal.css';
// // import Modal from './Modal';

// // const About = () => {
// //   const [isModalOpen, setIsModalOpen] = useState(false);
// //   const [selectedImage, setSelectedImage] = useState('');

// //   const openModal = (image) => {
// //     setSelectedImage(image);
// //     setIsModalOpen(true);
// //   }; 

// //   const closeModal = () => {
// //     setIsModalOpen(false);
// //     setSelectedImage('');
// //   };

// //   return (
// //     <section id="about" className="about">
// //       <div className="about-content">
// //         <h2 className="heading">About Us</h2>
// //         <p className="intro">
// //           A.O. Upright Technologies & Business Hub, founded by Akinwande Abdulrazaq Olaniyi, specializes in the fabrication, assembling, and installation of food processing and industrial equipment. With a commitment to innovation and customer satisfaction, we deliver exceptional products tailored to your needs.
// //         </p>

// //         <div className="mission-vision-values">
// //           <div className="mission-box">
// //             <h3>Our Mission</h3>
// //             <p>To provide high-quality technology solutions that enhance efficiency and productivity for our clients.</p>
// //           </div>
// //           <div className="vision-box">
// //             <h3>Our Vision</h3>
// //             <p>To be a leading provider of innovative industrial solutions across Africa, recognized for our quality and service excellence.</p>
// //           </div>
// //           <div className="values-box">
// //             <h3>Our Values</h3>
// //             <ul>
// //               <li>Integrity</li>
// //               <li>Innovation</li>
// //               <li>Customer Focus</li>
// //               <li>Excellence</li>
// //             </ul>
// //           </div>
// //         </div>

// //         <div className="certificates-section">
// //           <h3>Our Certifications</h3>
// //           <div className="certificate-gallery">
// //             <div className="certificate-item" onClick={() => openModal('https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726936123/cac_hmnqos.jpg')}>
// //               <img 
// //                 src="https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726936123/cac_hmnqos.jpg" 
// //                 alt="CAC Certificate" 
// //                 className="certificate-thumbnail"
// //               />
// //             </div>
// //             <div className="certificate-item" onClick={() => openModal('https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726936123/hicman_ljt2po.jpg')}>
// //               <img 
// //                 src="https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726936123/hicman_ljt2po.jpg" 
// //                 alt="Certificate 2" 
// //                 className="certificate-thumbnail"
// //               />
// //             </div>
// //             <div className="certificate-item" onClick={() => openModal('https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726936123/amefan_npvxcv.jpg')}>
// //               <img 
// //                 src="https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726936123/amefan_npvxcv.jpg" 
// //                 alt="Certificate 3" 
// //                 className="certificate-thumbnail"
// //               />
// //             </div>
// //           </div>
// //         </div>
// //       </div>
      
// //       <Modal isOpen={isModalOpen} onClose={closeModal} image={selectedImage} />
// //     </section>
// //   );
// // };

// // export default About;
// import React from 'react';
// import './About.css';

// const About = () => {
//   return (
//     <section id="about" className="about">
//       <h2>About Us</h2>
//       <p>
//         A.O. Upright Technologies & Business Hub, founded by Akinwande Abdulrazaq Olaniyi, specializes in the fabrication, assembling, and installation of food processing and industrial equipment.
//       </p>
      
//       {/* Certificates Section */}
//       <section className="certificates">
//         <div className="certificate-item">
//           <img src="https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726983073/WhatsApp_Image_2024-09-21_at_21.11.21_13875348_dndyip.jpg" alt="CAC Certificate" />
//         </div>
//         <div className="certificate-item">
//           <img src="https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726983073/WhatsApp_Image_2024-09-21_at_21.11.21_ef1ed539_x7okg7.jpg" alt="Trade test Certificate" />
//         </div>
//         <div className="certificate-item">
//           <img src="https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726983074/WhatsApp_Image_2024-09-21_at_21.11.21_3f10ee3f_lmmw2s.jpg" alt="Professional Certificate" />
//         </div>
//       </section>
//     </section>
//   );
// };

// export default About;
import React from 'react';
import './About.css';

const About = () => {
  return (
    <section id="about" className="about">
      <h2>About Us</h2>
      <p>
        A.O. Upright Technologies & Business Hub, founded by Akinwande Abdulrazaq Olaniyi, specializes in the fabrication, assembling, and installation of food processing and industrial equipment.
      </p>
      
      {/* Certificates Section */}
      <section className="certificates">
        <div className="certificate-item">
          <img src="https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726983073/WhatsApp_Image_2024-09-21_at_21.11.21_13875348_dndyip.jpg" alt="CAC Certificate" />
        </div>
        <div className="certificate-item">
          <img src="https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726983073/WhatsApp_Image_2024-09-21_at_21.11.21_ef1ed539_x7okg7.jpg" alt="Trade Test Certificate" />
        </div>
        <div className="certificate-item">
          <img src="https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726983074/WhatsApp_Image_2024-09-21_at_21.11.21_3f10ee3f_lmmw2s.jpg" alt="Professional Certificate" />
        </div>
      </section>

      {/* Custom Google Reviews Section */}
      <section className="reviews-section">
        <h3>What Our Customers Are Saying</h3>
        <p>We are grateful for the feedback from our satisfied customers. Here are some of the reviews left by our customers on Google:</p>

        {/* Google Reviews Embed */}
        {/* <div className="reviews-embed">
          <iframe
            src="https://www.google.com/maps/embed?pb=!4v1633968576347!6m8!1m7!1s0x103b9b3e06a404d1:0x24ab7553ff445df!2m2!1d6.4888488!2d3.3611886!3f250!4f0!5f0.7820865974627469"
            width="100%" 
            height="450" 
            style={{ border: 0 }} 
            allowFullScreen=""
            loading="lazy"
            title="Google Reviews"
          ></iframe>
        </div> */}

        {/* Prompt to Visit Google Maps for More Reviews */}
        <div className="more-reviews">
          <p>
            <a href="https://www.google.com/maps/place//data=!4m3!3m2!1s0x103b9b3e06a404d1:0x24ab7553ff445df!12e1?source=g.page.m.nr._&laa=nmx-review-solicitation-recommendation-card"
               target="_blank" 
               rel="noopener noreferrer">
              See more reviews on Google
            </a>
          </p>
        </div>
      </section>
    </section>
  );
};

export default About;


