import React from 'react';
import './service.css'; // Create this CSS file for custom styling

const ServicesPage = () => {
  // Sample services data
  const services = [
    {
      id: 1,
      title: 'Fabrication of Food Processing Equipment',
      description: 'We specialize in the fabrication of top-quality food processing equipment tailored to your specific industrial needs.',
      icon: '🔧', // You can replace this with an actual icon or image
    },
    {
      id: 2,
      title: 'Assembling and Installation',
      description: 'Our team provides professional assembling and installation services to ensure your equipment operates efficiently.',
      icon: '⚙️',
    },
    {
      id: 3,
      title: 'Project Scheduling and Deadlines',
      description: 'We adhere strictly to project timelines, ensuring every phase is delivered on schedule with no compromise on quality.',
      icon: '📅',
    },
    {
      id: 4,
      title: 'Live Client Communication',
      description: 'Our live communication ensures you are involved in every step, from design through to delivery, keeping you informed and engaged.',
      icon: '💬',
    },
    {
      id: 5,
      title: 'Quick Response to Queries',
      description: 'We pride ourselves on responding quickly to all inquiries, ensuring that any issues or questions are addressed promptly.',
      icon: '🚀',
    },
    {
      id: 6,
      title: 'Problem-Solving and Troubleshooting',
      description: 'Our experts are skilled at problem-solving and troubleshooting, ensuring smooth operations and minimal downtime.',
      icon: '🔍',
    },
  ];

  return (
    <div className="services-page">
      <section className="services-intro">
        <h1>Our Services</h1>
        <p>
          At A.O. Upright Technologies & Business Hub, we offer a wide range of professional services to meet your food processing and industrial equipment needs.
        </p>
      </section>

      <section className="services-list">
        {services.map((service) => (
          <div key={service.id} className="service-card">
            <div className="service-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </section>

      <section className="service-contact">
        <h2>Need More Information?</h2>
        <p>
          If you would like to know more about any of our services or have a specific request, don't hesitate to <a href="mailto:aouprighttechnologies@gmail.com">contact us</a>.
        </p>
      </section>
    </div>
  );
};

export default ServicesPage;
