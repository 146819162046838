// Header.js
import React, { useState } from 'react';
import './Header.css';
const logo ='https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726862735/cr3rawibcbqqdtpmpwnb.jpg'; // Import your logo image

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <a href="/"><img src={logo} alt="A.O.  Upright Technologies Logo" className="logo-image" /></a>
      </div>
      <button className="hamburger" onClick={toggleMenu}>
        ☰
      </button>
      <nav className={`nav ${isOpen ? 'active' : ''}`}>
        <ul>
          <li><a href="about">About Us</a></li>
          <li><a href="services">Services</a></li>
          <li><a href="portfolio">Products</a></li>
          <li><a href="contact">Contact Us</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
